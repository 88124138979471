import axios from 'axios';
import { API_CONFIG } from './config';

class ApiService {

    constructor() {
        this.client = axios.create({
            baseURL: API_CONFIG.BASE_URL,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.client.interceptors.response.use(
            response => response,
            error => {
                console.error('API Error:', error);

                // Special handling for shop hours 404
                if (error.response?.status === 404 &&
                    error.config.url.includes('/getItemByTableAndId') &&
                    error.config.params?.table_name === 'opening_hours') {
                    // Return default hours instead of throwing
                    return {
                        data: this.getDefaultShopHours()
                    };
                }

                if (!error.response) {
                    throw new Error('בעיית תקשורת - אנא בדקו את החיבור שלכם');
                }

                switch (error.response.status) {
                    case 404:
                        return null;
                    case 429:
                        throw new Error('יותר מדי בקשות - אנא נסו שוב בעוד מספר דקות');
                    default:
                        throw new Error(error.response.data?.message || 'אירעה שגיאה בלתי צפויה');
                }
            }
        );
    }

// In api.js
    async getMenu() {
        try {
            const response = await this.client.get(API_CONFIG.ENDPOINTS.GET_MENU, {
                params: {
                    table_name: 'new_menu',
                    item_id: 'ofek123'
                }
            });

            // Return the complete data for admin/edit views
            if (window.location.pathname.includes('/mgr-dashboard') ||
                window.location.pathname.includes('/edit-menu')) {
                return response.data;
            }

            // Return cleaned data for customer views
            const menuData = {...response.data};
            delete menuData.id;
            delete menuData.lut;
            return menuData;
        } catch (error) {
            console.error('Menu fetch error:', error);
            throw error;
        }
    }

    async getShopHours(date) {
        try {
            // First try to get specific hours for the date
            const response = await this.client.get(API_CONFIG.ENDPOINTS.SHOP_HOURS, {
                params: {
                    table_name: 'opening_hours',
                    item_id: date,
                    key_name: 'id'
                }
            });
            return response.data;
        } catch (error) {
            // If specific hours not found, try to get default hours
            if (error.response?.status === 404) {
                try {
                    const defaultResponse = await apiService.getShopHours('default');
                    return {
                        ...defaultResponse,
                        date: date,
                        is_default: true
                    };
                } catch (defaultError) {
                    // If even default hours not found, return hardcoded defaults
                    return this.getDefaultShopHours();
                }
            }
            throw error;
        }
    }

    getCurrentDate() {
        return new Date().toISOString().split('T')[0];
    }

    getTomorrowDate() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow.toISOString().split('T')[0];
    }

    getDefaultShopHours() {
        return {
            start_time: '09:00',
            end_time: '12:00',
            is_default: true
        };
    }

    async updateShopHours(hours) {
        const response = await this.client.post(API_CONFIG.ENDPOINTS.UPDATE_SHOP_HOURS, {
            table_name: 'opening_hours',
            id: hours.date || new Date().toISOString().split('T')[0],
            item_values: hours
        });
        return response.data;
    }

    async createOrder(orderData) {
        // Get next order number
        const counterResponse = await this.client.post(API_CONFIG.ENDPOINTS.ORDER_COUNTER);
        const { orderNumber } = counterResponse.data;

        // Create the order with the number
        const response = await this.client.post(API_CONFIG.ENDPOINTS.SET_ORDER, {
            id: orderData.id,
            order_content: {
                ...orderData,
                order_number: orderNumber
            }
        });

        return response.data;
    }

    async getNextOrderNumber() {
        const response = await this.client.post(API_CONFIG.ENDPOINTS.ORDER_COUNTER);
        return response.data.orderNumber;
    }

    async setNewOrder(newOrder) {
        // Get next order number first
        const orderNumber = await this.getNextOrderNumber();

        if (!newOrder.hasOwnProperty("creation_time")) {
            newOrder.creation_time = this.getCurrentDate();
        }

        // Add the order number
        newOrder.order_number = orderNumber;

        // Then update in DB
        return this.updateExistsOrder(newOrder);
    }

    async updateExistsOrder(updatedOrder) {
        const order_id = updatedOrder.id;
        const orderData = { ...updatedOrder };
        delete orderData.id;

        if (!orderData.hasOwnProperty("lut")) {
            orderData.lut = this.getCurrentDate();
        }

        const response = await this.client.post(API_CONFIG.ENDPOINTS.SET_ORDER, {
            id: order_id,
            order_content: orderData
        });

        return response.data;
    }

    async getOrderById(orderId) {
        const response = await this.client.get(API_CONFIG.ENDPOINTS.GET_ORDER, {
            params: { order_id: orderId }
        });
        if (response === null) {
            return null;
        }
        return response.data;
    }

    async getTodayOrders() {
        const response = await this.client.get(API_CONFIG.ENDPOINTS.GET_ORDERS_TODAY, {
            params: {
                table_name: 'my_orders',
                filter_today: true
            }
        });
        return response.data;
    }

    async generateLabels(orders) {
        const response = await this.client.post(API_CONFIG.ENDPOINTS.GENERATE_LABELS, orders);
        return response.data;
    }

    async updateMenu(menuData) {
        const response = await this.client.post(API_CONFIG.ENDPOINTS.UPDATE_MENU, {
            table_name: "new_menu",
            id: menuData.id,
            item_values: menuData
        });
        return response.data;
    }
    async getOrderByPhoneAndDate(phone, date) {
        try {
            // Create hash for the order ID
            const id = await this.createIdFromPhoneAndDate(phone, date);
// Get order by ID
            return this.getOrderById(id);
        } catch (error) {
            console.error('Error getting order:', error);
            throw error;
        }
    }

    async getOrderByPhoneToday(phone) {
        const today = new Date().toISOString().split('T')[0];
        return this.getOrderByPhoneAndDate(phone, today);
    }

    async createIdFromPhoneAndDate(phone, date) {
        if (!phone) {
            throw new Error('Cannot send order without phone number');
        }
        if (!date) {
            throw new Error('Cannot send order without date');
        }

        // Simple string-based hash function that works across all browsers
        function simpleHash(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                const char = str.charCodeAt(i);
                hash = ((hash << 5) - hash) + char;
                hash = hash & hash; // Convert to 32-bit integer
            }
            return Math.abs(hash).toString(16).padStart(8, '0');
        }

        date = date.toString();
        const combined = `${phone},${date}`;
        return simpleHash(combined);
    }

}

export const apiService = new ApiService();

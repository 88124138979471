import React, {useEffect, useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {Plus, Trash2, Image as ImageIcon, XCircle, CheckCircle2} from 'lucide-react';

const CategoryEditor = ({ category, categoryData, onChange, onImageUpload, onImageRemove, isUploading }) => {
    const [newOption, setNewOption] = useState('');
    const [imageLoadStatus, setImageLoadStatus] = useState('none');

    const checkImageUrl = (url) => {
        if (!url) {
            setImageLoadStatus('none');
            return;
        }

        const img = new Image();
        img.onload = () => setImageLoadStatus('success');
        img.onerror = () => setImageLoadStatus('error');
        img.src = url;
    };



    useEffect(() => {
        if (categoryData.image) {
            checkImageUrl(categoryData.image);
        }
    }, [categoryData.image]);

    const renderImageStatus = () => {
        if (isUploading) return <div className="text-gray-500">מעלה תמונה...</div>;
        if (!categoryData.image) return null;

        switch (imageLoadStatus) {
            case 'success':
                return (
                    <div className="flex items-center text-green-500">
                        <CheckCircle2 size={16} className="mr-1" />
                        <span>תמונה נטענה בהצלחה</span>
                    </div>
                );
            case 'error':
                return (
                    <div className="flex items-center text-red-500">
                        <XCircle size={16} className="mr-1" />
                        <span>שגיאה בטעינת התמונה</span>
                    </div>
                );
            default:
                return <div className="text-gray-500">בודק תמונה...</div>;
        }
    };



    const handleInputChange = (field, value) => {
        onChange({
            ...categoryData,
            [field]: value
        });
    };

    const handleOptionChange = (key, index, value) => {
        const newOptions = [...categoryData[key].options];
        newOptions[index] = value;
        onChange({
            ...categoryData,
            [key]: { ...categoryData[key], options: newOptions }
        });
    };

    const handleAddOption = (key) => {
        if (newOption.trim() !== '') {
            onChange({
                ...categoryData,
                [key]: {
                    ...categoryData[key],
                    options: [...(categoryData[key].options || []), newOption.trim()]
                }
            });
            setNewOption('');
        }
    };

    const handleDeleteOption = (key, index) => {
        const newOptions = categoryData[key].options.filter((_, i) => i !== index);
        onChange({
            ...categoryData,
            [key]: { ...categoryData[key], options: newOptions }
        });
    };

    const handleAmountChange = (key, field, value) => {
        onChange({
            ...categoryData,
            [key]: {
                ...categoryData[key],
                [field]: parseInt(value)
            }
        });
    };


    return (
        <div className="space-y-6">
            <h2 className="text-2xl font-bold mb-4">{category}</h2>
            <div className="bg-white p-6 rounded-lg shadow-md space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">סוג המנה</label>
                        <input
                            value={categoryData.name || ''}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">תיאור</label>
                        <input
                            value={categoryData.description || ''}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">מחיר</label>
                        <input
                            type="number"
                            value={categoryData.price || ''}
                            onChange={(e) => handleInputChange('price', parseFloat(e.target.value))}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">משקאות</label>
                        <input
                            type="checkbox"
                            checked={categoryData.drinks || false}
                            onChange={(e) => handleInputChange('drinks', e.target.checked)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                    </div>
                </div>
                <div className="space-y-2">
                    <div className="flex items-center justify-between">
                        <input
                            type="file"
                            id="category-image-upload"
                            className="hidden"
                            onChange={(e) => onImageUpload(e, 'category')}
                            disabled={isUploading}
                        />
                        <label
                            htmlFor="category-image-upload"
                            className="flex items-center justify-center p-2 border rounded cursor-pointer hover:bg-gray-100"
                        >
                            <ImageIcon className="mr-2" size={20}/>
                            {categoryData.image ? 'החלף תמונה' : 'הוסף תמונה'}
                        </label>
                        {renderImageStatus()}
                        {categoryData.image && !isUploading && (
                            <button
                                onClick={() => onImageRemove('category')}
                                className="p-1 bg-red-500 text-white rounded-full"
                            >
                                <Trash2 size={16}/>
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {Object.entries(categoryData)
                .filter(([key, value]) => typeof value === 'object' && value.options)
                .sort(([_, a], [__, b]) => (a.index || 0) - (b.index || 0))
                .map(([key, value]) => (

                    <motion.div key={key} className="bg-white p-6 rounded-lg shadow-md space-y-4">
                            <h3 className="text-xl font-semibold">{key}</h3>

                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        כמות מקסימלית
                                    </label>
                                    <input
                                        type="number"
                                        value={value.amount || 1}
                                        onChange={(e) => handleAmountChange(key, 'amount', e.target.value)}
                                        className="w-full p-2 border rounded"
                                        min="1"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        כמות חובה
                                    </label>
                                    <input
                                        type="number"
                                        value={value.mandatory || 0}
                                        onChange={(e) => handleAmountChange(key, 'mandatory', e.target.value)}
                                        className="w-full p-2 border rounded"
                                        min="0"
                                        max={value.amount || 1}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        סדר הצגה
                                    </label>
                                    <input
                                        type="number"
                                        value={value.index || 0}
                                        onChange={(e) => handleAmountChange(key, 'index', e.target.value)}
                                        className="w-full p-2 border rounded"
                                        min="0"
                                    />
                                </div>
                            </div>
                            <AnimatePresence>
                                {value.options.map((option, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{opacity: 0, y: 20}}
                                        animate={{opacity: 1, y: 0}}
                                        exit={{opacity: 0, y: -20}}
                                        className="flex items-center space-x-2"
                                    >
                                        <input
                                            value={option}
                                            onChange={(e) => handleOptionChange(key, index, e.target.value)}
                                            className="flex-grow p-2 border rounded"
                                        />
                                        <button
                                            onClick={() => handleDeleteOption(key, index)}
                                            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                                        >
                                            <Trash2 size={16}/>
                                        </button>
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                            <div className="flex items-center space-x-2">
                                <input
                                    value={newOption}
                                    onChange={(e) => setNewOption(e.target.value)}
                                    className="flex-grow p-2 border rounded"
                                    placeholder={`אפשרות ${key} חדשה`}
                                />
                                <button
                                    onClick={() => handleAddOption(key)}
                                    className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors"
                                >
                                    <Plus size={16}/>
                                </button>
                            </div>
                        </motion.div>
                ))}
        </div>
    );
};

export default CategoryEditor;
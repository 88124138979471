import {apiService} from "../services/api";

const getCurrentDate = () => new Date().toISOString().split('T')[0];

const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
};

const fetchHours = async (id) => {
    return await apiService.getShopHours(id);
};

export const fetchTodayHours = async () => {
    try {
        const todayData = await fetchHours(getCurrentDate());
        if (todayData && Object.keys(todayData).length > 0) {
            console.log("Fetched today's shop hours");
            return todayData;
        }

        console.log("Today's hours not found, fetching default hours");
        const defaultData = await fetchHours('default');
        if (defaultData && Object.keys(defaultData).length > 0) {
            const defaultWithDate = {
                ...defaultData,
                date: getCurrentDate()
            };
            console.log("Using default shop hours:", defaultWithDate);
            return defaultWithDate;
        }

        throw new Error('No shop hours found and no default hours set');
    } catch (error) {
        console.error('Error in fetchTodayHours:', error);
        throw error;
    }
};

export const fetchTomorrowHours = async () => {
    try {
        const tomorrowData = await fetchHours(getTomorrowDate());
        if (tomorrowData && Object.keys(tomorrowData).length > 0) {
            console.log("Fetched tomorrow's shop hours:", tomorrowData);
            return tomorrowData;
        }

        console.log("Tomorrow's hours not found, fetching default hours");
        const defaultData = await fetchHours('default');
        if (defaultData && Object.keys(defaultData).length > 0) {
            const defaultWithDate = {
                ...defaultData,
                date: getTomorrowDate()
            };
            console.log("Using default hours for tomorrow:", defaultWithDate);
            return defaultWithDate;
        }

        throw new Error('No shop hours found for tomorrow and no default hours set');
    } catch (error) {
        console.error('Error in fetchTomorrowHours:', error);
        throw error;
    }
};

export const updateShopHours = async (updatedHours) => {
    return await apiService.updateShopHours(updatedHours).item_values;
};
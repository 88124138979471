import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Home } from 'lucide-react';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center gap-6 text-center p-4">
            <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center">
                <span className="text-3xl">404</span>
            </div>
            <div>
                <h1 className="text-2xl font-semibold mb-2">העמוד לא נמצא</h1>
                <p className="text-gray-600 mb-6">מצטערים, העמוד שחיפשת לא קיים</p>
            </div>
            <button
                onClick={() => navigate('/')}
                className="flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
            >
                <Home size={20} />
                חזרה לדף הבית
            </button>
        </div>
    );
};

export default NotFound;
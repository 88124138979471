// ErrorState.js - update the styling
import {ContactButtons} from "./ContactInfo";
import {AlertCircle, RefreshCcw} from "lucide-react";
import {colors} from "../colors";

export const ErrorState = ({
                               message = 'Something went wrong',
                               onRetry,
                               showRetry = true,
                               showContact = false
                           }) => {
    return (
        <div className="flex flex-col items-center justify-center gap-6 text-center p-4">
            <div className="w-14 h-14 bg-gray-50 rounded-full flex items-center justify-center">
                <AlertCircle className="w-6 h-6 text-gray-700" />
            </div>

            <div>
                <h1 className="text-xl font-medium text-gray-900 mb-1">{message}</h1>
                {showContact && (
                    <p className="text-sm text-gray-500">
                        אנחנו עובדים על פתרון הבעיה. בינתיים, אתם מוזמנים ליצור איתנו קשר
                    </p>
                )}
            </div>

            <div className="w-full max-w-xs">
                {showRetry && onRetry && (
                    <button
                        onClick={onRetry}
                        className="w-full mb-3 flex items-center justify-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:opacity-95"
                        style={{ backgroundColor: colors.primary }}
                    >
                        <RefreshCcw className="w-5 h-5" />
                        <span className="font-medium">נסה שוב</span>
                    </button>
                )}

                {showContact && <ContactButtons />}
            </div>
        </div>
    );
};
import React from 'react';
import { Phone, MessageCircle, Mail, MapPin, X } from 'lucide-react';
import { colors } from '../colors';

const MAPS_URL = "https://maps.app.goo.gl/WrRMSnNwjwQDziA97";

export const ContactButtons = ({ closePopup = null, className = "" }) => (
    <div className={`flex flex-col gap-2.5 w-full ${className}`}>
        <a
            href="tel:0505984642"
            className="flex items-center justify-center gap-2 px-6 py-3 text-gray-700 rounded-lg transition-all hover:bg-gray-100 border border-gray-200 hover:border-gray-300"
        >
            <Phone size={18} className="text-gray-500" />
            <span className="font-medium">050-598-4642</span>
        </a>

        <a
            href="https://wa.me/972505984642"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 px-6 py-3 text-white rounded-lg transition-all hover:opacity-95"
            style={{ backgroundColor: colors.primary }}
        >
            <MessageCircle size={18} />
            <span className="font-medium">שלחו הודעה בWhatsApp</span>
        </a>

        <a
            href="mailto:beberskitchen@gmail.com"
            className="flex items-center justify-center gap-2 px-6 py-3 text-gray-700 rounded-lg transition-all hover:bg-gray-100 border border-gray-200 hover:border-gray-300"
        >
            <Mail size={18} className="text-gray-500" />
            <span className="font-medium">beberskitchen@gmail.com</span>
        </a>
    </div>
);

export const ContactInfo = ({ onClose = null, isPopup = true, showTitle = true }) => {
    return (
        <div className="bg-white rounded-xl p-6 max-w-sm w-full shadow-lg relative" dir="rtl">
            {isPopup && onClose && (
                <button
                    onClick={onClose}
                    className="absolute top-4 left-4 p-1.5 rounded-full hover:bg-gray-100 transition-colors"
                    aria-label="סגור חלון"
                >
                    <X size={20} className="text-gray-400" />
                </button>
            )}

            {showTitle && (
                <div className="mb-8">
                    <div className="w-14 h-14 bg-gray-50 rounded-full flex items-center justify-center mx-auto mb-4">
                        <Phone className="w-6 h-6 text-gray-700" />
                    </div>
                    <h2 className="text-xl font-medium text-center text-gray-900">
                        צור קשר
                    </h2>
                    <p className="text-sm text-gray-500 text-center mt-1">
                        אנחנו כאן לכל שאלה
                    </p>
                </div>
            )}

            <div className="space-y-6">
                <div className="flex items-start gap-3 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                    <MapPin className="w-5 h-5 text-gray-400 flex-shrink-0 mt-1"/>
                    <a
                        href={MAPS_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-right transition-colors hover:text-blue-600"
                    >
                        <p className="font-medium text-gray-900">המטבח של בבר</p>
                        <p className="text-gray-600 text-sm">קניון עופר אדומים</p>
                        <p className="text-gray-600 text-sm">קומה 2, מתחם מזון מהיר</p>
                    </a>
                </div>

                <ContactButtons/>
            </div>

            {isPopup && (
                <button
                    onClick={onClose}
                    className="w-full mt-6 py-2.5 px-4 rounded-lg text-gray-500 transition-colors hover:bg-gray-100 text-sm font-medium"
                >
                    סגור
                </button>
            )}
        </div>
    );
};

export default ContactInfo;
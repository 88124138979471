import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Skeleton = ({ className = "", ...props }) => (
  <div
    className={`animate-pulse bg-gray-200 rounded ${className}`}
    {...props}
  />
);

export const MenuItemSkeleton = () => (
  <div className="bg-white rounded-lg shadow-md p-4 mb-4">
    <div className="flex items-start space-x-4">
      <Skeleton className="w-24 h-24 rounded-lg" />
      <div className="flex-1 space-y-3">
        <Skeleton className="h-6 w-3/4" />
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="h-5 w-1/4" />
      </div>
    </div>
  </div>
);

export const OrderSummarySkeleton = () => (
  <div className="bg-white rounded-lg shadow-md p-4 mb-4">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center space-x-3">
        <Skeleton className="w-8 h-8 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-5 w-32" />
          <Skeleton className="h-4 w-24" />
        </div>
      </div>
      <Skeleton className="h-8 w-24 rounded-md" />
    </div>
  </div>
);

export const LoadingSpinner = () => (
  <motion.div 
    className="flex justify-center items-center p-4"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
  </motion.div>
);

export const PageLoader = () => (
  <div className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center z-50">
    <LoadingSpinner />
  </div>
);

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {Plus, Trash2, Image as ImageIcon, XCircle, CheckCircle2} from 'lucide-react';


const SpecialsEditor = ({
                            specials,
                            displayName,
                            onDisplayNameChange,
                            onSpecialChange,
                            onAddSpecial,
                            onDeleteSpecial,
                            onImageUpload,
                            onImageRemove,
                            isUploading
                        }) => {
    const [newSpecial, setNewSpecial] = useState({
        name: '',
        description: '',
        price: '',
        image: null
    });
    const [imageLoadStatus, setImageLoadStatus] = useState({});

    const handleInputChange = (e, id = null) => {
        const { name, value } = e.target;
        if (id) {
            onSpecialChange(id, name, name === 'price' ? parseFloat(value) || 0 : value);
        } else {
            setNewSpecial(prev => ({ ...prev, [name]: name === 'price' ? parseFloat(value) || 0 : value }));
        }
    };

    const handleAddSpecial = () => {
        if (newSpecial.name.trim() !== '') {
            onAddSpecial(newSpecial);
            setNewSpecial({ name: '', description: '', price: '', image: null });
        }
    };
    const checkImageUrl = (url, id) => {
        if (!url) {
            setImageLoadStatus(prev => ({ ...prev, [id]: 'none' }));
            return;
        }

        const img = new Image();
        img.onload = () => setImageLoadStatus(prev => ({ ...prev, [id]: 'success' }));
        img.onerror = () => setImageLoadStatus(prev => ({ ...prev, [id]: 'error' }));
        img.src = url;
    };
    React.useEffect(() => {
        specials.forEach(special => {
            if (special.image) {
                checkImageUrl(special.image, special.id);
            }
        });
    }, [specials]);
    const renderImageStatus = (id, image) => {
        if (isUploading) return <div className="text-gray-500">מעלה תמונה...</div>;
        if (!image) return null;

        switch (imageLoadStatus[id]) {
            case 'success':
                return (
                    <div className="flex items-center text-green-500">
                        <CheckCircle2 size={16} className="mr-1" />
                        <span>תמונה נטענה בהצלחה</span>
                    </div>
                );
            case 'error':
                return (
                    <div className="flex items-center text-red-500">
                        <XCircle size={16} className="mr-1" />
                        <span>שגיאה בטעינת התמונה</span>
                    </div>
                );
            default:
                return <div className="text-gray-500">בודק תמונה...</div>;
        }
    };


    return (
        <div className="space-y-4" dir="rtl">
            <h2 className="text-2xl font-bold mb-4">מנות ספיישל</h2>
            <div className="bg-white p-4 rounded-lg shadow-md">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    שם להצגה בתפריט
                </label>
                <input
                    value={displayName}
                    onChange={(e) => onDisplayNameChange(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="שם להצגה בתפריט"
                />
            </div>
            <AnimatePresence>
                {specials.map((special) => (
                    <motion.div
                        key={special.id}
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -20}}
                        className="bg-white p-4 rounded-lg shadow-md"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <input
                                name="name"
                                value={special.name}
                                onChange={(e) => handleInputChange(e, special.id)}
                                className="col-span-2 p-2 border rounded"
                                placeholder="שם המנה"
                            />
                            <input
                                name="description"
                                value={special.description}
                                onChange={(e) => handleInputChange(e, special.id)}
                                className="col-span-2 p-2 border rounded"
                                placeholder="תיאור המנה"
                            />
                            <input
                                type="number"
                                name="price"
                                value={special.price}
                                onChange={(e) => handleInputChange(e, special.id)}
                                className="p-2 border rounded"
                                placeholder="מחיר"
                            />
                            <div className="relative col-span-2">
                                <input
                                    type="file"
                                    id={`image-upload-${special.id}`}
                                    className="hidden"
                                    onChange={(e) => onImageUpload(e, special.id)}
                                    disabled={isUploading}
                                />
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor={`image-upload-${special.id}`}
                                        className="flex items-center justify-center p-2 border rounded cursor-pointer hover:bg-gray-100"
                                    >
                                        <ImageIcon className="ml-2" size={20}/>
                                        {special.image ? 'החלף תמונה' : 'הוסף תמונה'}
                                    </label>
                                    {renderImageStatus(special.id, special.image)}
                                    {special.image && !isUploading && (
                                        <button
                                            onClick={() => onImageRemove(special.id)}
                                            className="p-1 bg-red-500 text-white rounded-full"
                                        >
                                            <Trash2 size={16}/>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => onDeleteSpecial(special.id)}
                            className="mt-2 p-2 bg-red-500 text-white rounded hover:bg-red-600"
                        >
                            מחיקה
                        </button>
                    </motion.div>
                ))}
            </AnimatePresence>
            <motion.div
                key="new-special-form"
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                className="bg-white p-4 rounded-lg shadow-md"
            >
                <div className="grid grid-cols-2 gap-4">
                    <input
                        name="name"
                        value={newSpecial.name}
                        onChange={handleInputChange}
                        className="col-span-2 p-2 border rounded"
                        placeholder="שם מנת ספיישל חדשה"
                    />
                    <input
                        name="description"
                        value={newSpecial.description}
                        onChange={handleInputChange}
                        className="col-span-2 p-2 border rounded"
                        placeholder="תיאור מנת ספיישל חדשה"
                    />
                    <input
                        type="number"
                        name="price"
                        value={newSpecial.price}
                        onChange={handleInputChange}
                        className="p-2 border rounded"
                        placeholder="מחיר מנת ספיישל חדשה"
                    />
                    <button
                        onClick={handleAddSpecial}
                        className="flex items-center justify-center p-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                        <Plus size={20} className="ml-2" />
                        הוסף ספיישל
                    </button>
                </div>
            </motion.div>
        </div>
    );
};

export default SpecialsEditor;
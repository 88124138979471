export const API_CONFIG = {
    BASE_URL: 'https://utmwe6pof6.execute-api.us-east-1.amazonaws.com',
    ENDPOINTS: {
        SET_ORDER: '/setOrPlaceOrder',
        GET_ORDER: '/getOrderByID',
        GET_ORDERS_TODAY: '/getAllFromDB',
        GET_MENU: '/getItemByTableAndId',
        ORDER_COUNTER: '/order-counter-lambda',
        SHOP_HOURS: '/getItemByTableAndId',
        UPDATE_SHOP_HOURS: '/mutation-in-item',
        UPDATE_MENU: '/mutation-in-item',
        GENERATE_LABELS: '/label-generator'
    }
};


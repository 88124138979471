import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Phone, ArrowRight } from 'lucide-react';
import { colors } from '../colors';
import { apiService } from '../services/api';
import {ErrorState} from "./ErrorState";
import {LoadingSpinner} from "./loading-components";

const ExistingOrder = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handlePhoneChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 10) value = value.slice(0, 10);
        if (value.length > 0 && value[0] !== '0') value = '0' + value;
        setPhoneNumber(value);
    };

    const handleSubmit = async () => {
        if (!phoneNumber.match(/^05\d{8}$/)) {
            alert('יש להזין מספר טלפון תקין');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const order = await apiService.getOrderByPhoneToday(phoneNumber);
            if (order) {
                navigate('/view-order', { state: { order } });
            } else {
                alert('לא נמצאה הזמנה עבור מספר טלפון זה להיום');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return (
            <ErrorState
                message={error}
                onRetry={() => setError(null)}
                showContact={true}
            />
        );
    }

    return (
        <div className="w-full p-4 rounded-lg shadow-lg bg-white h-full flex flex-col justify-center">
            <div className="overflow-y-auto">
                <h1 className="text-xl font-bold mb-2 text-center" style={{color: colors.primary}}>
                    הזמנה קיימת
                </h1>
                <p className="text-sm mb-3 text-center" style={{color: colors.text}}>
                    הזן את מספר הטלפון שלך כדי למצוא את ההזמנה הקיימת
                </p>

                <div className="space-y-2 mb-3">
                    <div className="relative">
                        <Phone className="absolute top-2 right-2" size={16} color={colors.primary}/>
                        <input
                            type="tel"
                            placeholder="מספר טלפון"
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            className="w-full p-2 pr-8 text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary"
                            style={{direction: 'rtl'}}
                            disabled={isLoading}
                            autoComplete="tel-national"
                            inputMode="tel"
                            pattern="05[0-9]{8}"
                            maxLength="10"
                        />
                    </div>
                </div>

                <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="w-full py-2 rounded-md text-sm font-semibold text-white transition-colors relative overflow-hidden"
                    style={{backgroundColor: colors.primary}}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center">
                            <LoadingSpinner />
                            <span className="ml-2">מחפש הזמנה...</span>
                        </div>
                    ) : 'מצא הזמנה'}
                </button>

                <button
                    onClick={() => navigate('/')}
                    className="w-full mt-2 py-2 px-4 rounded-md text-xs flex items-center justify-center transition-colors"
                    style={{color: colors.primary}}
                    disabled={isLoading}
                >
                    <span>חזור למסך הכניסה</span>
                    <ArrowRight className="mr-1" size={14}/>
                </button>
            </div>
        </div>
    );
};

export default ExistingOrder;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Phone, Users, ShoppingBag, ArrowLeft, Plus, Minus } from 'lucide-react';
import { colors } from '../colors';
import MealCard from './MealCard';
import ClosedMessage from "./closed-message-component";
import { fetchTodayHours } from "../api/shopHoursApi";
import MealPopup from './MealPopup';
import {logDOM} from "@testing-library/react";
import {apiService} from "../services/api";
import {ErrorState} from "./ErrorState"; // Import the new MealPopup component

const NewOrderForm = ({ toggleContactPopup }) => {
    const MAX_GROUP_SIZE = 30;
    const navigate = useNavigate();
    const location = useLocation();
    const { phoneNumber, name, orderType } = location.state || {};
    const [error, setError] = useState(null);
    const [isRestaurantOpen, setIsRestaurantOpen] = useState(true);
    const [responsibleName] = useState(name || '');
    const [groupSize, setGroupSize] = useState(orderType === 'group' ? Math.min(2, MAX_GROUP_SIZE) : 1);
    const [orders, setOrders] = useState(orderType === 'group' ? 
        [{ name: responsibleName, meal: '' }, { name: '', meal: '' }] : 
        [{ name: '', meal: '' }]
    );
    const [menuData, setMenuData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [editingOrderIndex, setEditingOrderIndex] = useState(null);
    const [showMealPopup, setShowMealPopup] = useState(false);
    const [selectedMealData, setSelectedMealData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiError, setApiError] = useState(false);

    useEffect(() => {
        if (showMealPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showMealPopup]);

    useEffect(() => {
        if (!phoneNumber || !name || !orderType) {
            navigate('/');
        }
        fetchMenuData();
        checkRestaurantStatus();
    }, [phoneNumber, name, orderType, navigate]);

    const fetchMenuData = async () => {
        setIsLoading(true);
        setError(null);
        setApiError(false);
        try {
            const data = await apiService.getMenu(); // Added await here
            if (!data) {
                throw new Error('Failed to fetch menu data');
            }
            const menuDataCleaned = {...data};
            delete menuDataCleaned.id;
            delete menuDataCleaned.lut;
            setMenuData(menuDataCleaned);
            if (data && Object.keys(data).length > 0) {
                setSelectedCategory(Object.keys(data)[0]);
            }
        } catch (err) {
            console.error("Error fetching menu data:", err);
            setApiError(true);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const checkRestaurantStatus = async () => {
        try {
            const hours = await fetchTodayHours();
            const now = new Date();
            const currentTime = now.getHours() * 60 + now.getMinutes();
            const [startHour, startMinute] = hours.start_time.split(':').map(Number);
            const [endHour, endMinute] = hours.end_time.split(':').map(Number);
            const startTime = startHour * 60 + startMinute;
            const endTime = endHour * 60 + endMinute;

            // console.log('Current time:', currentTime);
            // console.log('Start time:', startTime);
            // console.log('End time:', endTime);
            console.log('Is restaurant open:', currentTime >= startTime && currentTime <= endTime && hours.end_time === false);

            setIsRestaurantOpen(currentTime >= startTime && currentTime <= endTime);
        } catch (error) {
            console.error('Error checking restaurant status:', error);
            setIsRestaurantOpen(false);
        }
    };

    const handleAddPerson = () => {
        if (orders.length < MAX_GROUP_SIZE) {
            setGroupSize(prev => prev + 1);
            setOrders(prev => [...prev, { name: '', meal: '' }]);
        }
    };

    const handleRemovePerson = (index) => {
        const newOrders = orders.filter((_, i) => i !== index);
        setOrders(newOrders);
    };

    const handleOrderChange = (index, field, value) => {
        const newOrders = [...orders];
        newOrders[index][field] = value;
        setOrders(newOrders);
    };

    const handleMealSelection = (index) => {
        setEditingOrderIndex(index);
        setShowMealPopup(true); // Open the meal selection popup
    };

    const handleMealChosen = (mealDescription) => {
        const newOrders = [...orders];
        console.log("mealDescription", mealDescription)
        newOrders[editingOrderIndex].meal = mealDescription;
        setOrders(newOrders);
        setShowMealPopup(false); // Close the popup after selecting a meal
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            const now = new Date();
            const utcTimestamp = now.toISOString();
            const todayFormatted = now.toISOString().slice(0, 10);

            // Get the next order number from the Lambda
            const id = await apiService.createIdFromPhoneAndDate(phoneNumber, todayFormatted);

            if(responsibleName === null || responsibleName === "")
                throw new Error("no responsibleName for the order")

            const finalOrder = {
                id,
                owner_name: responsibleName,
                phone_num: phoneNumber,
                orderType,
                lut: utcTimestamp,
                order_number: -1,
                creation_time: utcTimestamp,
                groupSize: orderType === 'group' ? groupSize : 1,
                meals: orderType === 'group' ? orders : [{ name: responsibleName, meal: orders[0].meal }]
            };

            const response = await apiService.setNewOrder(finalOrder);
            console.log("New order set successfully:", response);
            navigate('/view-order', {
                state: { order: response }
            });
        }
        catch (error) {
            setError(error.message);
            console.error("Error setting new order:", error);
            // alert("Error creating order. Please try again.");
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const isCartEmpty = orders.every(order => !order.meal);

    if (error ) {
        return (
            <ErrorState
                message={error}
                onRetry={handleSubmit}
            />
        );
    }

    if (isLoading) {
        return <div className="text-center p-4">טוען את התפריט...</div>;
    }


    if (!isRestaurantOpen) {
        return <ClosedMessage onContactClick={toggleContactPopup} />;
    }


    return (
        <div className="flex flex-col min-h-screen bg-gray-50 relative">
            {/* Header */}
            <div className="bg-white shadow-sm px-4 py-3 sticky top-0 z-10">
                <h2 className="text-xl font-medium text-center">
                    {orderType === 'group' ? `הזמנה קבוצתית (${orders.length}/${MAX_GROUP_SIZE})` : 'הזמנה בודדת'}
                </h2>
            </div>

            {/* Main Content */}
            <div className="flex-grow overflow-y-auto">
                <div className="p-4 space-y-3 max-w-lg mx-auto pb-24">
                    {orders.map((order, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-sm p-4 border border-gray-100">
                            {orderType === 'group' && index > 0 && (
                                <input
                                    type="text"
                                    placeholder="שם המזמין"
                                    value={order.name}
                                    onChange={(e) => handleOrderChange(index, 'name', e.target.value)}
                                    className="w-full p-2 pl-10 rounded-lg border border-gray-300"
                                />
                            )}
                            <div
                                onClick={() => handleMealSelection(index)}
                                className="w-full p-2 rounded-lg border border-gray-300 cursor-pointer flex items-center"
                            >
                                <ShoppingBag className="mr-2 text-gray-400" size={20}/>
                                <span>{order.meal || 'בחר מנה'}</span>
                            </div>
                            {orderType === 'group' && index > 0 && (
                                <button
                                    onClick={() => handleRemovePerson(index)}
                                    className="w-full mt-2 p-2 rounded-lg text-white bg-red-500"
                                >
                                    <Minus size={16} className="mr-1"/>
                                    הסר מנה
                                </button>
                            )}
                        </div>
                    ))}

                    {orderType === 'group' && orders.length < MAX_GROUP_SIZE && (
                        <button
                            onClick={handleAddPerson}
                            className="w-full p-2 rounded-lg text-white transition-colors flex items-center justify-center"
                            style={{backgroundColor: colors.secondary}}
                        >
                            <Plus size={16} className="mr-1"/>
                            הוסף מנה ({orders.length}/{MAX_GROUP_SIZE})
                        </button>
                    )}
                </div>
            </div>

            {/* Submit Button */}
            <div className={`fixed bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-white via-white to-transparent pb-safe ${showMealPopup ? 'z-0' : 'z-20'}`}>
                <button
                    onClick={handleSubmit}
                    className={`w-full p-4 rounded-xl text-white font-medium transition-colors
                    ${isCartEmpty || isSubmitting || showMealPopup ?
                        'opacity-50 bg-gray-400 pointer-events-none' :
                        'bg-blue-600 active:bg-blue-700'}`}
                    disabled={isCartEmpty || isSubmitting || showMealPopup}
                >
                    {isSubmitting ? 'מעבד הזמנה...' : 'סיים הזמנה'}
                </button>
            </div>

            {/* Meal Selection Modal */}
            {showMealPopup && (
                <div className="fixed inset-0 z-50">
                    <div
                        className="absolute inset-0 bg-black/50"
                        onClick={() => setShowMealPopup(false)}
                    />
                    <div className="relative z-50">
                        <MealPopup
                            menuData={menuData}
                            drinks={menuData.drinks}
                            onClose={() => setShowMealPopup(false)}
                            onSelect={handleMealChosen}
                        />
                    </div>
                </div>
            )}

            <style>{`
            @keyframes loading {
                0% { transform: translateX(-100%); }
                100% { transform: translateX(400%); }
            }
        `}</style>
        </div>
    );
};

export default NewOrderForm;

// src/config/aws-config.js

const awsConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  bucket: process.env.REACT_APP_AWS_BUCKET,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
};

// Validate required environment variables
const requiredEnvVars = [
  'REACT_APP_AWS_REGION',
  'REACT_APP_AWS_BUCKET',
  'REACT_APP_AWS_ACCESS_KEY_ID',
  'REACT_APP_AWS_SECRET_ACCESS_KEY'
];

const missingEnvVars = requiredEnvVars.filter(envVar => !process.env[envVar]);

if (missingEnvVars.length > 0) {
  throw new Error(
    `Missing required environment variables: ${missingEnvVars.join(', ')}\n` +
    'Please check your .env file and ensure all required variables are set.'
  );
}

export default awsConfig;

import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Phone } from 'lucide-react';
import { fetchTodayHours } from "../api/shopHoursApi";
import ClosedMessage from "./closed-message-component";
import { colors } from '../colors';
import logo from '../assets/logoBeber.jpeg';  // Adjust the path based on your file structure

const HEADER_HEIGHT = 64;
const MANAGEMENT_ROUTES = ['/mgr-dashboard', '/login-mgr', '/edit-menu'];
const USER_AFTER_HOURS_ROUTES = ['/', '/existing-order', '/view-order'];

// Layout.jsx
const Layout = ({ children, toggleContactPopup, isShopOpen }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/';
    const isExistingOrders = location.pathname === '/';
    const [needsScroll, setNeedsScroll] = useState(false);
    const contentRef = useRef(null);
    const containerRef = useRef(null);

    const isManagementPage = MANAGEMENT_ROUTES.includes(location.pathname);
    const isUserAfterHours = USER_AFTER_HOURS_ROUTES.includes(location.pathname);

    useEffect(() => {
        const checkContentOverflow = () => {
            if (contentRef.current && containerRef.current) {
                const contentHeight = contentRef.current.scrollHeight;
                const containerHeight = containerRef.current.clientHeight;
                setNeedsScroll(contentHeight > containerHeight);
            }
        };

        const resizeObserver = new ResizeObserver(checkContentOverflow);

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        checkContentOverflow();

        return () => {
            resizeObserver.disconnect();
        };
    }, [children]);

    const renderContent = () => {
        if (!isManagementPage && !isShopOpen && !isLoginPage &&!isUserAfterHours) {
            return (
                <div className="w-full h-full flex items-center justify-center px-4">
                    <ClosedMessage onContactClick={toggleContactPopup} />
                </div>
            );
        }

        const contentWrapperClass = needsScroll
            ? 'h-full overflow-y-auto'
            : 'h-full flex items-center justify-center';

        return (
            <div ref={containerRef} className={contentWrapperClass}>
                <div
                    ref={contentRef}
                    className={`w-full px-4 ${isLoginPage ? 'max-w-md' : 'max-w-7xl'} mx-auto`}
                >
                    {children}
                </div>
            </div>
        );
    };

    return (
        <div className="fixed inset-0 flex flex-col" style={{ backgroundColor: colors.background }}>
            <header
                className="flex-shrink-0 shadow-sm"
                style={{
                    backgroundColor: colors.white,
                    height: `${HEADER_HEIGHT}px`
                }}
            >
                <div className="h-full max-w-7xl mx-auto px-4 flex justify-between items-center">
                    <img src={logo} className="h-10 w-10 rounded-full" alt="logo"/>
                    <Link to="/" className="text-xl font-semibold" style={{ color: colors.primary }}>
                        המטבח של בבר - הזמנות
                    </Link>
                    <button
                        onClick={toggleContactPopup}
                        className="p-2 rounded-full transition-colors"
                        style={{ backgroundColor: colors.secondary }}
                    >
                        <Phone size={20} color={colors.white} />
                    </button>
                </div>
            </header>

            <main className="flex-grow overflow-hidden">
                {renderContent()}
            </main>
        </div>
    );
};

export default Layout;
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Trash2 } from 'lucide-react';

const DrinksEditor = ({ drinks = [], onChange }) => {
    const [newDrink, setNewDrink] = useState('');

    const handleAddDrink = () => {
        if (newDrink.trim() !== '') {
            const updatedDrinks = Array.isArray(drinks) ? [...drinks, newDrink.trim()] : [newDrink.trim()];
            onChange(updatedDrinks);
            setNewDrink('');
        }
    };

    const handleDeleteDrink = (index) => {
        const updatedDrinks = drinks.filter((_, i) => i !== index);
        onChange(updatedDrinks);
    };

    const handleEditDrink = (index, newName) => {
        const updatedDrinks = [...drinks];
        updatedDrinks[index] = newName;
        onChange(updatedDrinks);
    };

    return (
        <div className="space-y-4" dir="rtl">
            <h2 className="text-2xl font-bold mb-4">משקאות</h2>
            <AnimatePresence>
                {Array.isArray(drinks) && drinks.map((drink, index) => (
                    <motion.div
                        key={`${drink}-${index}`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="flex items-center space-x-2 bg-white p-3 rounded-lg shadow-md"
                    >
                        <input
                            value={drink}
                            onChange={(e) => handleEditDrink(index, e.target.value)}
                            className="flex-grow p-2 border rounded"
                        />
                        <button
                            onClick={() => handleDeleteDrink(index)}
                            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors mr-2"
                        >
                            <Trash2 size={16} />
                        </button>
                    </motion.div>
                ))}
            </AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex items-center space-x-2 bg-white p-3 rounded-lg shadow-md"
            >
                <input
                    value={newDrink}
                    onChange={(e) => setNewDrink(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleAddDrink();
                        }
                    }}
                    className="flex-grow p-2 border rounded"
                    placeholder="משקה חדש"
                />
                <button
                    onClick={handleAddDrink}
                    className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors mr-2"
                >
                    <Plus size={16} />
                </button>
            </motion.div>
        </div>
    );
};

export default DrinksEditor;
import React from 'react';
import { Phone } from 'lucide-react';

const ClosedMessage = ({ onContactClick }) => {
  return (
      <div className="h-full flex items-center justify-center">
        <div className="mx-4 p-6 md:p-8 bg-white rounded-2xl shadow-lg max-w-md w-full text-center">
          <div className="mb-6">
            <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <Phone className="w-8 h-8 text-red-500" />
            </div>
            <h2 className="text-2xl font-semibold mb-2 text-gray-800">
              המטבח סגור כרגע
            </h2>
            <p className="text-gray-600 mb-6">
              מצטערים, המטבח של בבר סגור כעת להזמנות. אנא נסו שוב מאוחר יותר או צרו איתנו קשר.
            </p>
          </div>
          <button
              onClick={onContactClick}
              className="w-full py-3 px-4 bg-blue-600 text-white rounded-xl font-medium hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            ליצירת קשר לחץ כאן
          </button>
        </div>
      </div>
  );
};

export default ClosedMessage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, confirmSignIn, fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';

export default function MgrLoginComponent() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordRequired, setChangePasswordRequired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      const user = await getCurrentUser();
      if (user) {
        console.log('User is already signed in');
        const tokens = await getAuthTokens();
        navigateToDashboard(tokens);
      }
    } catch (error) {
      console.log('No authenticated user');
    }
  }

  async function handleSignIn(event) {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    
    try {
      // Check if a user is already signed in
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          // If a user is already signed in, sign them out first
          await signOut();
          console.log('Signed out previous user');
        }
      } catch (error) {
        // No user is signed in, proceed with sign in
      }

      console.log('Attempting to sign in with username:', username);
      const { nextStep } = await signIn({ username, password });
      
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        console.log('New password required');
        setChangePasswordRequired(true);
      } else {
        console.log('User is signed in successfully');
        const tokens = await getAuthTokens();
        navigateToDashboard(tokens);
      }
    } catch (error) {
      console.error('Error signing in:', error);
      setError(error.message || 'התחברות נכשלה. אנא נסה שוב.');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleChangePassword(event) {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    if (newPassword !== confirmNewPassword) {
      setError('הסיסמאות החדשות אינן תואמות. אנא נסה שוב.');
      setIsLoading(false);
      return;
    }

    try {
      await confirmSignIn({ 
        challengeResponse: newPassword,
        options: {
          userAttributes: {
            name: name
          }
        }
      });
      console.log('Password changed successfully');
      const tokens = await getAuthTokens();
      navigateToDashboard(tokens);
    } catch (error) {
      console.error('Error changing password:', error);
      setError(error.message || 'שינוי הסיסמה נכשל. אנא נסה שוב.');
    } finally {
      setIsLoading(false);
    }
  }

  async function getAuthTokens() {
    try {
      const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};
      return {
        idToken: idToken.toString(),
        accessToken: accessToken.toString()
      };
    } catch (error) {
      console.error('Error fetching auth tokens:', error);
      return null;
    }
  }

  function navigateToDashboard(tokens) {
    if (tokens) {
      navigate('/mgr-dashboard', { state: { authTokens: tokens } });
    } else {
      console.error('No tokens available for navigation');
      setError('Unable to authenticate. Please try again.');
    }
  }

  const renderForm = () => {
    if (changePasswordRequired) {
      return (
        <form onSubmit={handleChangePassword}>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="name" style={{ display: 'block', marginBottom: '0.5rem' }}>
              שם מלא
            </label>
            <input
              id="name"
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                width: '100%',
                padding: '0.5rem',
                border: '1px solid #d1d5db',
                borderRadius: '0.25rem',
              }}
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="newPassword" style={{ display: 'block', marginBottom: '0.5rem' }}>
              סיסמה חדשה
            </label>
            <input
              id="newPassword"
              type="password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{
                width: '100%',
                padding: '0.5rem',
                border: '1px solid #d1d5db',
                borderRadius: '0.25rem',
              }}
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="confirmNewPassword" style={{ display: 'block', marginBottom: '0.5rem' }}>
              אימות סיסמה חדשה
            </label>
            <input
              id="confirmNewPassword"
              type="password"
              required
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              style={{
                width: '100%',
                padding: '0.5rem',
                border: '1px solid #d1d5db',
                borderRadius: '0.25rem',
              }}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            style={{
              width: '100%',
              padding: '0.5rem',
              backgroundColor: '#4f46e5',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer',
              opacity: isLoading ? 0.7 : 1,
            }}
          >
            {isLoading ? 'מעדכן סיסמה...' : 'עדכן סיסמה'}
          </button>
        </form>
      );
    } else {
      return (
        <form onSubmit={handleSignIn}>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="username" style={{ display: 'block', marginBottom: '0.5rem' }}>
              שם משתמש
            </label>
            <input
              id="username"
              name="username"
              type="text"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                width: '100%',
                padding: '0.5rem',
                border: '1px solid #d1d5db',
                borderRadius: '0.25rem',
              }}
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="password" style={{ display: 'block', marginBottom: '0.5rem' }}>
              סיסמא
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: '100%',
                padding: '0.5rem',
                border: '1px solid #d1d5db',
                borderRadius: '0.25rem',
              }}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            style={{
              width: '100%',
              padding: '0.5rem',
              backgroundColor: '#4f46e5',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer',
              opacity: isLoading ? 0.7 : 1,
            }}
          >
            {isLoading ? 'מתחבר...' : 'התחבר'}
          </button>
        </form>
      );
    }
  };

  return (
    <div style={{ 
      minHeight: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      backgroundColor: '#f3f4f6' 
    }}>
      <div style={{ 
        maxWidth: '400px', 
        width: '100%', 
        padding: '2rem', 
        backgroundColor: 'white', 
        borderRadius: '0.5rem', 
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }}>
        <h2 style={{ 
          marginBottom: '2rem', 
          fontSize: '1.5rem', 
          fontWeight: 'bold', 
          textAlign: 'center' 
        }}>
          {changePasswordRequired ? 'שינוי סיסמה זמנית' : 'התחברות - מנהלים'}
        </h2>
        {error && (
          <div style={{ 
            color: '#dc2626', 
            backgroundColor: '#fee2e2', 
            padding: '0.5rem', 
            borderRadius: '0.25rem', 
            marginBottom: '1rem' 
          }}>
            {error}
          </div>
        )}
        {renderForm()}
      </div>
    </div>
  );
}
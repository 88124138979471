import axios from 'axios';



export const printOrders = async (orders) => {
    try {

        console.log('orders:', orders);

        const response = await axios.post('https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/label-generator', orders, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        });

        if (response.data && response.data.pdfUrl) {
            // Open PDF in new window/tab
            window.open(response.data.pdfUrl, '_blank');
            return true;
        } else {
            console.error('Unexpected response format:', response.data);
            throw new Error('No PDF URL received from server');
        }
    } catch (error) {
        console.error('Error generating labels:', error);
        throw error;
    }
};

import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import awsConfig from "./config-setup";

const s3Client = new S3Client({
  region: awsConfig.region,
  credentials: {
    accessKeyId: awsConfig.credentials.accessKeyId,
    secretAccessKey: awsConfig.credentials.secretAccessKey,
  }
});


export const uploadImageToS3 = async (file) => {
  if (!file) return null;

  try {
    // Generate a unique file name
    const fileExtension = file.name.split('.').pop();
    const uniqueFileName = `${Date.now()}-${Math.random().toString(36).substring(2)}.${fileExtension}`;
    
    // Set the S3 parameters
    const params = {
      Bucket: awsConfig.bucket,
      Key: uniqueFileName,
      Body: file,
      ContentType: file.type,
    };

    // Upload to S3
    await s3Client.send(new PutObjectCommand(params));

    await s3Client.send(new PutObjectCommand(params));
    return `https://${awsConfig.bucket}.s3.${awsConfig.region}.amazonaws.com/${uniqueFileName}`;
  } catch (error) {
    console.error('Error uploading image to S3:', error);
    throw new Error('Failed to upload image');
  }
};

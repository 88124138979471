import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Phone, User, ArrowRight, Users, Clock} from 'lucide-react';
import { colors } from '../colors';
import { apiService } from '../services/api';
import {ErrorState} from "./ErrorState";
import {LoadingSpinner} from "./loading-components";
import {fetchTodayHours} from "../api/shopHoursApi";

const Login = ({ toggleContactPopup, isShopOpen, shopHours, isLoadingShopStatus }) => {
    const [step, setStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTypeLoading, setIsTypeLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async () => {
        const phoneRegex = /^05\d{8}$/;
        if (!phoneNumber || !phoneRegex.test(phoneNumber) || !name) {
            alert('יש להזין מספר טלפון ושם תקינים');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const existingOrder = await apiService.getOrderByPhoneToday(phoneNumber);
            if (existingOrder) {
                navigate('/view-order', { state: { order: existingOrder } });
                return;
            }
            setStep(2);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOrderTypeSelection = async (type) => {
        setIsTypeLoading(true);
        try {
            // Simulate minimum loading time for better UX
            await new Promise(resolve => setTimeout(resolve, 500));
            navigate('/order', { state: { phoneNumber, name, orderType: type } });
        } catch (err) {
            setError(err.message);
        } finally {
            setIsTypeLoading(false);
        }
    };

    const handleExistingOrder = () => {
        navigate('/existing-order');
    };

    const handlePhoneChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 10) value = value.slice(0, 10);
        if (value.length > 0 && value[0] !== '0') value = '0' + value;
        setPhoneNumber(value);
    };

    if (error) {
        return (
            <ErrorState
                message={error}
                onRetry={() => setError(null)}
                showContact={true}
            />
        );
    }

    if (isLoadingShopStatus) {
        return (
            <div className="w-full p-4 rounded-lg shadow-lg bg-white h-full flex flex-col justify-center">
                <div className="text-center">
                    <LoadingSpinner />
                    <p className="mt-2 text-gray-600">טוען נתונים...</p>
                </div>
            </div>
        );
    }


    if (!isShopOpen) {
        return (
            <div className="w-full p-4 rounded-lg shadow-lg bg-white h-full flex flex-col justify-center">
                <div className="text-center space-y-4">
                    <div className="w-16 h-16 bg-amber-100 rounded-full flex items-center justify-center mx-auto">
                        <Clock className="w-8 h-8 text-amber-600" />
                    </div>
                    <h1 className="text-xl font-bold" style={{color: colors.primary}}>
                        המטבח סגור כרגע
                    </h1>
                    {shopHours && (
                        <p className="text-sm text-gray-600">
                            שעות פעילות היום: {shopHours.start_time} - {shopHours.end_time}
                        </p>
                    )}
                    <p className="text-sm text-gray-600 mb-6">
                        לא ניתן להזמין כרגע, אבל אתה יכול לצפות בהזמנה קיימת
                    </p>

                    <button
                        onClick={handleExistingOrder}
                        className="w-full py-3 px-4 rounded-md text-sm font-semibold text-white transition-colors flex items-center justify-center gap-2"
                        style={{backgroundColor: colors.primary}}
                    >
                        <span>צפייה בהזמנה קיימת</span>
                        <ArrowRight size={14}/>
                    </button>

                    <button
                        onClick={toggleContactPopup}
                        className="w-full mt-2 py-2 px-4 rounded-md text-xs flex items-center justify-center transition-colors"
                        style={{color: colors.primary}}
                    >
                        <span>ליצירת קשר לחץ כאן</span>
                    </button>
                </div>
            </div>
        );
    }


    return (
        <div className="w-full p-4 rounded-lg shadow-lg bg-white h-full flex flex-col justify-center">
            <div className="overflow-y-auto">
                {step === 1 ? (
                    <>
                        <h1 className="text-xl font-bold mb-2 text-center" style={{color: colors.primary}}>
                            ברוכים השבים!
                        </h1>
                        <p className="text-sm mb-3 text-center" style={{color: colors.text}}>
                            הזן את פרטיך להזמנה חדשה
                        </p>

                        <div className="space-y-2 mb-3">
                            <div className="relative">
                                <Phone className="absolute top-2 right-2" size={16} color={colors.primary}/>
                                <input
                                    type="tel"
                                    placeholder="מספר טלפון"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    className="w-full p-2 pr-8 text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary"
                                    style={{direction: 'rtl'}}
                                    disabled={isLoading}
                                    autoComplete="tel-national"
                                    inputMode="tel"
                                    pattern="05[0-9]{8}"
                                    maxLength="10"
                                />
                            </div>
                            <div className="relative">
                                <User className="absolute top-2 right-2" size={16} color={colors.primary}/>
                                <input
                                    type="text"
                                    placeholder="שם"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="w-full p-2 pr-8 text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary"
                                    style={{direction: 'rtl'}}
                                    disabled={isLoading}
                                    autoComplete="name"
                                />
                            </div>
                        </div>
                        <button
                            onClick={handleLogin}
                            disabled={isLoading}
                            className="w-full h-10 rounded-md text-sm font-semibold text-white transition-colors relative overflow-hidden"
                            style={{backgroundColor: colors.primary}}
                        >
                            <div className="flex items-center justify-center h-full min-h-[40px]">
                                {isLoading ? (
                                    <>
                                        <LoadingSpinner size={20}/>
                                        <span className="mr-2">מאמת פרטים...</span>
                                    </>
                                ) : (
                                    <span>המשך</span>
                                )}
                            </div>
                        </button>

                        <button
                            onClick={handleExistingOrder}
                            className="w-full h-10 mt-2 py-2 px-4 rounded-md text-xs flex items-center justify-center transition-colors"
                            style={{color: colors.primary}}
                            disabled={isLoading}
                        >
                            <span>יש לי הזמנה קיימת</span>
                            <ArrowRight className="mr-1" size={14}/>
                        </button>
                    </>
                ) : (
                    <>
                        <h1 className="text-xl font-bold mb-3 text-center" style={{color: colors.primary}}>
                            בחר סוג הזמנה
                        </h1>
                        <div className="space-y-2 mb-3">
                            <button
                                onClick={() => handleOrderTypeSelection('single')}
                                disabled={isTypeLoading}
                                className="w-full h-10 py-2 rounded-md text-sm font-semibold text-white transition-colors flex items-center justify-center"
                                style={{backgroundColor: colors.primary}}
                            >
                                <User className="ml-2" size={16}/>
                                <span>הזמנה בודדת</span>
                            </button>

                            <button
                                onClick={() => handleOrderTypeSelection('group')}
                                disabled={isTypeLoading}
                                className="w-full h-10 py-2 rounded-md text-sm font-semibold text-white transition-colors flex items-center justify-center"
                                style={{backgroundColor: colors.secondary}}
                            >
                                <Users className="ml-2" size={16}/>
                                <span>הזמנה קבוצתית</span>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
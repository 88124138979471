const amplifyconfiguration = {
    Auth: {
      Cognito: {
        userPoolId: 'us-east-1_RgGS9CDim',
        userPoolClientId: '4ut2kmqld63t0ek4bivg2lfnvs',
      }
    }
};

export default amplifyconfiguration;

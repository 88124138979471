import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Edit3, FileText, Clock, Save } from 'lucide-react';
import MenuEditComponent from './MenuEditComponent';
import OrderSummary from './OrdersSummery';
import ShopHoursComponent from './ShopHoursComponent';
import localMenuData from './../editedMenu.json';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {apiService} from "../services/api";


const ManagementDashboard = () => {
  const [activeComponent, setActiveComponent] = useState('editMenu');
  const [menuData, setMenuData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const authTokens = location.state?.authTokens;
  const navigate = useNavigate();

  useEffect(() => {

    const fetchMenuData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await apiService.getMenu(); // Added await here
        setMenuData(data);
        console.log("Menu set from API data");
      } catch (err) {
        console.log('Failed to fetch menu data, using local menu');
        setMenuData(localMenuData);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMenuData();

    if (authTokens) {
      console.log('ID Token:', authTokens.idToken);
      console.log('Access Token:', authTokens.accessToken);
    } else {
      console.error('No auth tokens available');
      navigate('/login-mgr')
      
    }
  }, [authTokens]);

  const handleSaveMenu = async (updatedMenuData) => {
    setIsSaving(true);
    setError(null);
    try {
      const currentTimestamp = new Date().toISOString();
      updatedMenuData.lut = currentTimestamp;
      console.log(updatedMenuData)
      setMenuData(updatedMenuData);
      console.log('Menu saved locally');

      const mutation_req = {
        "table_name": "new_menu",
        "id": updatedMenuData.id,
        "item_values": updatedMenuData
      };

      const response = await fetch("https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/mutation-in-item", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mutation_req)
      });

      if (!response.ok) {
        throw new Error('Failed to save menu to API');
      }

      const result = await response.json();
      setMenuData(result);
      console.log('Menu saved to API:', result);
    } catch (error) {
      console.error('Error saving menu data:', error);
      setError('Failed to save menu data: ' + error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const renderComponent = () => {
    if (isLoading) {
      return <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>;
    }

    if (error) {
      return <div className="text-red-500 text-center">Error: {error}</div>;
    }

    switch (activeComponent) {
      case 'editMenu':
        return (
            <MenuEditComponent
                initialMenuData={menuData}
                onSave={handleSaveMenu}
                isSaving={isSaving}
            />
        );
      case 'viewOrders':
        return <OrderSummary />;
      case 'ShopHoursComponent':
        return <ShopHoursComponent />;
      default:
        return <div>Select a component</div>;
    }
  };

  return (
      <div className="flex flex-col min-h-screen bg-gray-100" dir="rtl">
        <nav className="bg-blue-600 p-4 shadow-md">
          <ul className="flex justify-around">
            <li>
              <button
                  onClick={() => setActiveComponent('editMenu')}
                  className={`flex items-center px-4 py-2 rounded-md transition-colors ${activeComponent === 'editMenu' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-500'}`}
              >
                <Edit3 className="ml-2" size={18} />
                עריכת תפריט יומי
              </button>
            </li>
            <li>
              <button
                  onClick={() => setActiveComponent('viewOrders')}
                  className={`flex items-center px-4 py-2 rounded-md transition-colors ${activeComponent === 'viewOrders' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-500'}`}
              >
                <FileText className="ml-2" size={18} />
                צפייה בהזמנות
              </button>
            </li>
            <li>
              <button
                  onClick={() => setActiveComponent('ShopHoursComponent')}
                  className={`flex items-center px-4 py-2 rounded-md transition-colors ${activeComponent === 'ShopHoursComponent' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-500'}`}
              >
                <Clock className="ml-2" size={18} />
                פתיחת להזמנות
              </button>
            </li>
          </ul>
        </nav>

        <motion.div
            className="flex-grow p-6 relative"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
          {renderComponent()}
          {isSaving && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-4 rounded-lg shadow-lg flex items-center">
                  <Save className="animate-spin mr-2" size={24} />
                  <span>שומר תפריט...</span>
                </div>
              </div>
          )}
        </motion.div>
      </div>
  );
};

export default ManagementDashboard;
import React, { useCallback, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Save, Plus, AlertCircle } from 'lucide-react';
import SpecialsEditor from './SpecialsEditor';
import CategoryEditor from './CategoryEditor';
import DrinksEditor from './DrinksEditor';
import { uploadImageToS3 } from "../lib/s3-image-upload";
import { apiService } from '../services/api';
import {ErrorState} from "./ErrorState";
import {LoadingSpinner} from "./loading-components";

const categories = [
    { id: 'specials', title: "הספיישל היומי", icon: '🌟' },
    { id: 'drinks', title: "משקאות", icon: '🥤' },
    { id: 'inBread', title: "עסקית בלחם", icon: '🥪' },
    { id: 'plateMeals', title: "עסקית בחמגשית", icon: '🍱' },
    { id: 'salad', title: "סלט בהרכבה", icon: '🥗' }
];

const MenuEditComponent = () => {
    const [menuData, setMenuData] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    // Fetch menu data
// Inside MenuEditComponent.js, modify the fetchMenu function:

    const fetchMenu = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await apiService.getMenu();
            const processedData = processMenuData(data);
            setMenuData(processedData);

            // Extract and sort categories from menu data
            const extractedCategories = Object.entries(processedData)
                .filter(([key, value]) => {
                    // Include both categories with display_name and the drinks category
                    return value?.display_name || key === 'drinks';
                })
                .map(([key, value]) => ({
                    id: key,
                    title: key === 'drinks' ? 'משקאות' : value.display_name,
                    index: value.index || 0
                }))
                .sort((a, b) => (a.index || 0) - (b.index || 0));

            setCategories(extractedCategories);
            setSelectedCategory(extractedCategories[0]?.id || 'specials');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchMenu();
    }, []);

    const processMenuData = (data) => {
        let processedData = { ...data };
        if (Array.isArray(processedData.specials)) {
            processedData.specials = {
                display_name: "ספיישל היומי",
                values: processedData.specials.map(special => ({
                    ...special,
                    id: special.id || `special-${Date.now()}-${Math.random()}`
                }))
            };
        } else if (!processedData.specials) {
            processedData.specials = {
                display_name: "ספיישל היומי",
                values: []
            };
        }
        return processedData;
    };

    const handleSpecialChange = (id, field, value) => {
        setMenuData(prevData => ({
            ...prevData,
            specials: {
                ...prevData.specials,
                values: prevData.specials.values.map(special =>
                    special.id === id ? { ...special, [field]: value } : special
                )
            }
        }));
    };

    const handleAddSpecial = (newSpecial) => {
        setMenuData(prevData => ({
            ...prevData,
            specials: {
                ...prevData.specials,
                values: [...prevData.specials.values, { ...newSpecial, id: `special-${Date.now()}-${Math.random()}` }]
            }
        }));
    };

    const handleDeleteSpecial = (id) => {
        setMenuData(prevData => ({
            ...prevData,
            specials: {
                ...prevData.specials,
                values: prevData.specials.values.filter(special => special.id !== id)
            }
        }));
    };

    const handleCategoryChange = (category, newCategoryData) => {
        setMenuData(prevData => ({ ...prevData, [category]: newCategoryData }));
    };

    const handleImageUpload = useCallback(async (event, id) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploading(true);
            try {
                const imageUrl = await uploadImageToS3(file);
                if (id !== 'category') {
                    handleSpecialChange(id, 'image', imageUrl);
                } else {
                    setMenuData(prev => ({
                        ...prev,
                        [selectedCategory]: {
                            ...prev[selectedCategory],
                            image: imageUrl
                        }
                    }));
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            } finally {
                setIsUploading(false);
            }
        }
    }, [selectedCategory]);

    const handleImageRemove = useCallback((id) => {
        handleSpecialChange(id, 'image', null);
    }, []);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await apiService.updateMenu({ ...menuData, lut: Date.now() });
            // Refresh menu data after save
            await fetchMenu();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsSaving(false);
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <LoadingSpinner />
            </div>
        );
    }

    if (error) {
        return (
            <ErrorState
                message={error}
                onRetry={fetchMenu}
            />
        );
    }

    if (!menuData || Object.keys(menuData).length === 0) {
        return (
            <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-500 to-purple-600">
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-center p-8 bg-white rounded-lg shadow-xl"
                >
                    <AlertCircle className="mx-auto mb-4 text-yellow-500" size={48} />
                    <p className="text-xl font-semibold text-gray-800">אין נתוני תפריט זמינים</p>
                    <p className="mt-2 text-gray-600">אנא הוסף קטגוריות ופריטים</p>
                </motion.div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-gray-100" dir="rtl">
            {/* Sidebar */}
            <div className="w-64 bg-white shadow-md">
                <div className="p-4">
                    <h1 className="text-2xl font-bold text-gray-800">עריכת תפריט</h1>
                    <p className="text-sm text-gray-600 mt-1">
                        עודכן: {new Date(menuData.lut).toLocaleString('he-IL')}
                    </p>
                </div>
                <nav className="mt-4">
                    {categories.map((category) => (
                        <button
                            key={category.id}
                            onClick={() => setSelectedCategory(category.id)}
                            className={`w-full text-right p-3 flex items-center space-x-3 ${
                                selectedCategory === category.id
                                    ? 'bg-blue-100 text-blue-600'
                                    : 'text-gray-600 hover:bg-gray-100'
                            }`}
                        >
                            <span className="text-xl">{category.icon}</span>
                            <span>{category.title}</span>
                        </button>
                    ))}
                </nav>
            </div>

            {/* Main content */}
            <div className="flex-1 p-8 overflow-auto">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={selectedCategory}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        {selectedCategory === 'specials' && (
                            <SpecialsEditor
                                specials={menuData.specials.values || []}
                                displayName={menuData.specials.display_name}
                                onSpecialChange={handleSpecialChange}
                                onAddSpecial={handleAddSpecial}
                                onDeleteSpecial={handleDeleteSpecial}
                                onImageUpload={handleImageUpload}
                                onImageRemove={handleImageRemove}
                                isUploading={isUploading}
                                onDisplayNameChange={(newName) => {
                                    setMenuData(prev => ({
                                        ...prev,
                                        specials: {
                                            ...prev.specials,
                                            display_name: newName
                                        }
                                    }));
                                }}
                            />
                        )}
                        {selectedCategory === 'drinks' && (
                            <DrinksEditor
                                drinks={menuData.drinks || []}
                                onChange={(newDrinks) => handleCategoryChange('drinks', newDrinks)}
                            />
                        )}
                        {['inBread', 'plateMeals', 'salad'].includes(selectedCategory) && (
                            <CategoryEditor
                                category={categories.find(c => c.id === selectedCategory).title}
                                categoryData={menuData[selectedCategory] || {}}
                                onChange={(newData) => handleCategoryChange(selectedCategory, newData)}
                                onImageUpload={handleImageUpload}
                                onImageRemove={handleImageRemove}
                                isUploading={isUploading}
                            />
                        )}
                    </motion.div>
                </AnimatePresence>
            </div>

            {/* Save button */}
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleSave}
                className="fixed bottom-8 right-8 flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
                <Save className="ml-2 h-5 w-5" aria-hidden="true" />
                שמור תפריט
            </motion.button>
        </div>
    );
};

export default MenuEditComponent;
import React, { useState, useEffect, useCallback } from 'react';
import { Clock } from 'lucide-react';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Alert, AlertDescription, AlertTitle } from "./ui/alert"
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "./ui/dialog"
import {fetchTodayHours, fetchTomorrowHours, updateShopHours} from '../api/shopHoursApi';

const ShopHoursComponent = () => {
    const [shopHours, setShopHours] = useState(null);
    const [tomorrowHours, setTomorrowHours] = useState(null);
    const [formHours, setFormHours] = useState({ startTime: '', endTime: '' });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);


    const fetchHours = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const todayData = await fetchTodayHours();
            setShopHours(todayData);
            const tomorrowData = await fetchTomorrowHours();
            setTomorrowHours(tomorrowData);
        } catch (error) {
            console.error('Error fetching shop hours:', error);
            setError('Failed to load shop hours. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
      fetchHours();
    }, [fetchHours]);

    useEffect(() => {
      if (shopHours) {
        setFormHours({
          startTime: shopHours.start_time,
          endTime: shopHours.end_time
        });
      }
    }, [shopHours]);

    const handleUpdateHours = async (updatedHours) => {
        setIsLoading(true);
        setError(null);
        try {
            const updatedData = await updateShopHours(updatedHours);
            setShopHours(updatedData);
        } catch (error) {
            console.error('Error updating shop hours:', error);
            setError('Failed to update shop hours. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormHours(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      const updatedHours = {
        start_time: formHours.startTime,
        end_time: formHours.endTime,
        actual_end_time: shopHours?.actual_end_time,
      };
      handleUpdateHours(updatedHours);
    };

    const handleCloseEarly = () => {
      const now = new Date();
      const currentTime = now.toTimeString().slice(0, 5);
      const updatedHours = {
        ...shopHours,
        actual_end_time: currentTime,
      };
      handleUpdateHours(updatedHours);
      setIsCloseDialogOpen(false);
    };

    const handleReopenShop = () => {
      const updatedHours = {
        ...shopHours,
        actual_end_time: null,
      };
      handleUpdateHours(updatedHours);
    };

    const isWithinOpenHours = () => {
        if (!shopHours) return false;
        const now = new Date();
        const currentTime = now.getHours() * 60 + now.getMinutes();
        const [startHour, startMinute] = shopHours.start_time.split(':').map(Number);
        const [endHour, endMinute] = shopHours.end_time.split(':').map(Number);
        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        return currentTime >= startTime && currentTime <= endTime;
    };


    const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  if (isLoading) {
    return (
        <Card className="w-full max-w-md mx-auto">
          <CardContent className="p-6">
            <div className="flex justify-center items-center h-40">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
            <p className="text-center mt-4">Loading shop hours...</p>
          </CardContent>
        </Card>
    );
  }

  const isShopClosed = shopHours && shopHours.actual_end_time;
    const isOutsideOpenHours = !isWithinOpenHours();


    return (
        <Card className="w-full max-w-md mx-auto">
            <CardHeader>
                <CardTitle className="flex items-center justify-center">
                    <Clock className="mr-2" />
                    שעות פתיחה לתאריך -  {getCurrentDate()}
                </CardTitle>
            </CardHeader>
            <CardContent>
                {error && (
                    <Alert variant="destructive" className="mb-4">
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
                {shopHours && (
                    <div className="space-y-4 mb-4">
                        <div className="text-center">
                            <p className="text-lg">
                                {isShopClosed ? (
                                    <span className="text-red-500 font-semibold">המסעדה סגורה</span>
                                ) : isOutsideOpenHours ? (
                                    <span className="text-orange-500 font-semibold">המסעדה סגורה כעת</span>
                                ) : (
                                    <>
                                        פתוח: <span className="font-semibold">{shopHours.start_time}</span> -
                                        <span className="font-semibold">{shopHours.end_time}</span>
                                    </>
                                )}
                            </p>
                            {isShopClosed && (
                                <p className="text-sm text-red-500 mt-1">סגירה מוקדמת התבצעה בשעה  {shopHours.actual_end_time}</p>
                            )}
                            {isOutsideOpenHours && !isShopClosed && (
                                <p className="text-sm text-orange-500 mt-1">המסעדה תפתח שוב ב-{tomorrowHours.start_time}</p>
                            )}
                        </div>
                        {!isOutsideOpenHours && (
                            isShopClosed ? (
                                <Button
                                    onClick={handleReopenShop}
                                    className="mx-auto px-4 py-2 bg-green-500 hover:bg-green-600 text-white text-sm rounded-md shadow-md transition-all duration-300 ease-in-out transform hover:scale-105"
                                >
                                    פתיחה מחדש
                                </Button>
                            ) : (
                                <Dialog open={isCloseDialogOpen} onOpenChange={setIsCloseDialogOpen}>
                                    <DialogTrigger asChild>
                                        <Button
                                            className="mx-auto px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm rounded-md shadow-md transition-all duration-300 ease-in-out transform hover:scale-105"
                                        >
                                            סגור מוקדם כעת
                                        </Button>
                                    </DialogTrigger>
                                    <DialogContent className="bg-white">
                                        <DialogHeader>
                                            <DialogTitle>סגירה מוקדמת</DialogTitle>
                                            <DialogDescription>
                                                אתה בטוח שאתה רוצה לסגור את המסעדה מוקדם?
                                            </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter>
                                            <Button variant="outline" onClick={() => setIsCloseDialogOpen(false)}>השאר פתוח</Button>
                                            <Button className="bg-red-600 hover:bg-red-700 text-white" onClick={handleCloseEarly}>סגור מוקדם</Button>
                                        </DialogFooter>
                                    </DialogContent>
                                </Dialog>
                            )
                        )}
                    </div>
                )}
                {
                    (isOutsideOpenHours || !isShopClosed) && (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <label htmlFor="startTime" className="block text-sm font-medium mb-1">שעת פתיחה</label>
                                    <Input
                                        type="time"
                                        id="startTime"
                                        name="startTime"
                                        value={formHours.startTime}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="flex-1">
                                    <label htmlFor="endTime" className="block text-sm font-medium mb-1">שעת סגירה</label>
                                    <Input
                                        type="time"
                                        id="endTime"
                                        name="endTime"
                                        value={formHours.endTime}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <Button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white">
                                עדכן שעות
                            </Button>
                        </form>
                    )
                }
            </CardContent>
        </Card>
    );
};

export default ShopHoursComponent;
import React, {useEffect, useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertCircle } from 'lucide-react';

const MobilePopup = ({ menuData, drinks, onClose, onSelect }) => {
    const [selectedCategory, setSelectedCategory] = useState(() => {
        const sortedCategories = Object.entries(menuData)
            .filter(([_, value]) => value?.display_name)
            .sort(([_, a], [__, b]) => (a.index || 0) - (b.index || 0));

        return sortedCategories[0]?.[0];
    });
    const [currentSelections, setCurrentSelections] = useState({});
    const [showDrinks, setShowDrinks] = useState(false);

    useEffect(() => {
        if (selectedCategory === 'inBread') {
            const extraSelection = currentSelections['תוספת'] || [];
            setShowDrinks(extraSelection.includes('שתייה קלה'));

            // Clear drinks selection if שתייה קלה is deselected
            if (!extraSelection.includes('שתייה קלה')) {
                setCurrentSelections(prev => {
                    const { drinks, ...rest } = prev;
                    return rest;
                });
            }
        }
    }, [currentSelections['תוספת'], selectedCategory]);


    const toggleSelection = (key, option, maxAmount) => {
        setCurrentSelections(prev => {
            const currentSelected = prev[key] || [];

            if (currentSelected.includes(option)) {
                return {
                    ...prev,
                    [key]: currentSelected.filter(item => item !== option)
                };
            } else if (currentSelected.length < maxAmount) {
                return {
                    ...prev,
                    [key]: [...currentSelected, option]
                };
            }
            return {
                ...prev,
                [key]: [...currentSelected.slice(1), option]
            };
        });
    };

    const isValidSelection = (categoryData) => {
        if (!categoryData) return false;

        // For specials, require at least a name selection
        if (selectedCategory === 'specials') {
            if (!categoryData.name || !categoryData.price) {
                return false;
            }
            // No additional validation needed for specials
            return true;
        }

        // For regular categories (including salad)
        const choiceKeys = Object.entries(categoryData)
            .filter(([_, value]) => typeof value === 'object' && value.options)
            .sort(([_, a], [__, b]) => (a.index || 0) - (b.index || 0));

        // If there are no options to choose from but it's not specials,
        // the category shouldn't be selectable
        if (choiceKeys.length === 0) return false;

        // Check that all mandatory selections are made
        return choiceKeys.every(([key, value]) => {
            const selectedCount = (currentSelections[key] || []).length;
            const mandatoryCount = value.mandatory || 0;
            return selectedCount >= mandatoryCount;
        });
    };

    const getMissingSelections = (categoryData) => {
        if (!categoryData) return [];

        // For specials
        if (selectedCategory === 'specials') {
            const missing = [];
            if (!categoryData.name) missing.push('שם המנה');
            return missing;
        }

        // For regular categories
        return Object.entries(categoryData)
            .filter(([_, value]) => typeof value === 'object' && value.options)
            .sort(([_, a], [__, b]) => (a.index || 0) - (b.index || 0))
            .filter(([key, value]) => {
                const selectedCount = (currentSelections[key] || []).length;
                const mandatoryCount = value.mandatory || 0;
                return selectedCount < mandatoryCount;
            })
            .map(([key]) => key);
    };
    const renderMealCards = () => {
        const categoryData = menuData[selectedCategory];
        if (!categoryData) return null;

        if (selectedCategory === 'specials' && categoryData.values) {
            return categoryData.values.map((meal) => (
                <motion.div
                    key={meal.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-white rounded-lg shadow-sm mb-3 overflow-hidden"
                >
                    {meal.image && (
                        <div className="relative h-48 bg-gray-100">
                            <img
                                src={meal.image}
                                alt={meal.name}
                                className="w-full h-full object-cover"
                            />
                            {meal.price && (
                                <div className="absolute top-3 left-3 bg-white px-3 py-1 rounded-full shadow-lg">
                                    <span className="font-semibold">₪{meal.price}</span>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="p-4">
                        <h3 className="text-lg font-bold mb-1">{meal.name}</h3>
                        {meal.description && (
                            <p className="text-gray-600 text-sm mb-3">{meal.description}</p>
                        )}
                        <button
                            onClick={() => onSelect(`${meal.name} - ₪${meal.price}`)}
                            className="w-full bg-blue-600 text-white py-2 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                        >
                            בחר מנה
                        </button>
                    </div>
                </motion.div>
            ));
        }


        const choiceKeys = Object.entries(categoryData)
            .filter(([key, value]) => typeof value === 'object' && value.options)
            .sort(([_, a], [__, b]) => (a.index || 0) - (b.index || 0));

        return (
            <div className="space-y-4">
                {categoryData.image && (
                    <div className="relative h-48 bg-gray-100 rounded-lg overflow-hidden">
                        <img
                            src={categoryData.image}
                            alt={categoryData.name}
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}

                <div className="bg-white rounded-lg p-4">
                    <h3 className="text-xl font-bold mb-2">{categoryData.name}</h3>
                    <p className="text-gray-600 mb-4">{categoryData.description}</p>
                    <div className="text-lg font-semibold mb-4">₪{categoryData.price}</div>
                </div>

                {choiceKeys.map(([key, value]) => {
                    const selectedCount = (currentSelections[key] || []).length;
                    const isMandatory = value.mandatory > 0;
                    const missingCount = value.mandatory - selectedCount;

                    return (
                        <div key={key} className="bg-white rounded-lg p-4">
                            <div className="flex justify-between items-center mb-2">
                                <div>
                                    <h4 className="font-semibold">
                                        {key}
                                        {isMandatory && <span className="text-red-500">*</span>}
                                    </h4>
                                    <p className="text-sm text-gray-500">
                                        {isMandatory ? (
                                            missingCount > 0 ?
                                                `יש לבחור ${value.mandatory} פריטים (חסרים ${missingCount})` :
                                                `נבחרו ${selectedCount} מתוך ${value.mandatory} הנדרשים`
                                        ) : (
                                            `אופציונלי (עד ${value.amount} פריטים)`
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="space-y-2 mt-3">
                                {value.options.map(option => (
                                    <button
                                        key={option}
                                        onClick={() => toggleSelection(key, option, value.amount)}
                                        className={`w-full p-3 rounded-lg text-right transition-colors flex justify-between items-center
                      ${(currentSelections[key] || []).includes(option)
                                            ? 'bg-blue-100 text-blue-700 font-medium'
                                            : 'bg-gray-50 hover:bg-gray-100'}`}
                                    >
                                        <span>{option}</span>
                                        {(currentSelections[key] || []).includes(option) && (
                                            <div className="w-2 h-2 rounded-full bg-blue-600" />
                                        )}
                                    </button>
                                ))}
                            </div>
                        </div>
                    );
                })}

                {/* Drinks section - show based on both conditions */}
                {((categoryData.drinks && !['inBread'].includes(selectedCategory)) ||
                    (selectedCategory === 'inBread' && showDrinks)) && (
                    <div className="bg-white rounded-lg p-4 mt-4">
                        <div className="flex justify-between items-center mb-2">
                            <div>
                                <h4 className="font-semibold">משקאות</h4>
                                <p className="text-sm text-gray-500">
                                    אופציונלי (עד משקה אחד)
                                </p>
                            </div>
                        </div>

                        <div className="space-y-2 mt-3">
                            {menuData.drinks?.map(drink => (
                                <button
                                    key={drink}
                                    onClick={() => toggleSelection('drinks', drink, 1)}
                                    className={`w-full p-3 rounded-lg text-right transition-colors flex justify-between items-center
                    ${(currentSelections['drinks'] || []).includes(drink)
                                        ? 'bg-blue-100 text-blue-700 font-medium'
                                        : 'bg-gray-50 hover:bg-gray-100'}`}
                                >
                                    <span>{drink}</span>
                                    {(currentSelections['drinks'] || []).includes(drink) && (
                                        <div className="w-2 h-2 rounded-full bg-blue-600" />
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const formatOrderedSelections = (categoryData, selections) => {
        // Get all choice keys with their data and indices
        const choiceKeys = Object.entries(categoryData)
            .filter(([key, value]) => typeof value === 'object' && value.options)
            .map(([key, value]) => ({
                key,
                index: value.index || 0,
                values: selections[key] || []
            }))
            .sort((a, b) => a.index - b.index); // Sort by index

        // Build selections in the correct order
        const orderedSelections = choiceKeys
            .map(({ key, values }) => {
                if (values.length === 0) return null;
                if (key === 'drinks') {
                    return `משקה: ${values.join(', ')}`;
                }
                return `${key}: ${values.join(', ')}`;
            })
            .filter(Boolean);

        // Add drink selection at the end if it exists
        if (selections.drinks?.length > 0) {
            orderedSelections.push(`משקה: ${selections.drinks.join(', ')}`);
        }

        return orderedSelections.join(' | ');
    };

    const handleConfirmSelection = () => {
        const categoryData = menuData[selectedCategory];

        // Format the selections in the correct order
        const orderedSelectionsString = formatOrderedSelections(categoryData, currentSelections);

        // Create the final meal description
        const mealDescription = `${categoryData.name} (${orderedSelectionsString}) - ₪${categoryData.price}`;

        onSelect(mealDescription);
    };

    return (
        <div className="fixed inset-0 bg-black/50 z-50 flex flex-col">
            <div className="bg-white rounded-t-xl mt-auto h-[90vh] w-full flex flex-col">
                <div className="px-4 py-3 border-b flex items-center justify-between">
                    <button onClick={onClose} className="p-1">
                        <X className="h-6 w-6" />
                    </button>
                    <h2 className="text-lg font-bold">תפריט</h2>
                    <div className="w-6" />
                </div>

                <div className="border-b bg-white">
                    <div className="flex justify-center p-2">
                        <div className="inline-flex bg-gray-100 p-1 rounded-xl">
                            {Object.entries(menuData)
                                .filter(([_, value]) => value?.display_name)
                                .sort(([_, a], [__, b]) => (a.index || 0) - (b.index || 0)) // Sort by index
                                .map(([key, value]) => (
                                    <button
                                        key={key}
                                        onClick={() => {
                                            setSelectedCategory(key);
                                            setCurrentSelections({});
                                        }}
                                        className={`px-4 py-2 rounded-lg text-sm font-medium transition-all
              ${selectedCategory === key
                                            ? 'bg-white text-blue-600 shadow-sm'
                                            : 'text-gray-600 hover:text-gray-900'}`}
                                    >
                                        {value.display_name}
                                    </button>
                                ))}
                        </div>
                    </div>
                </div>

                <div className="flex-1 overflow-y-auto">
                    <div className="p-4">
                        {renderMealCards()}
                    </div>
                </div>

                {/* Updated button positioning */}
                <div
                    className="sticky bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-white via-white to-transparent">
                    <motion.button
                        onClick={handleConfirmSelection}
                        disabled={!isValidSelection(menuData[selectedCategory])}
                        className={`w-full p-4 rounded-lg text-white font-medium mb-safe
                        ${!isValidSelection(menuData[selectedCategory])
                            ? 'bg-gray-400'
                            : 'bg-blue-600 hover:bg-blue-700'
                        } transition-colors`}
                    >
                        {!isValidSelection(menuData[selectedCategory])
                            ? `יש להשלים את הבחירה ב: ${getMissingSelections(menuData[selectedCategory]).join(', ')}`
                            : 'הוסף להזמנה'}
                    </motion.button>
                </div>
            </div>
        </div>
    );
};

export default MobilePopup;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Clock, User, Phone, Users, Utensils } from 'lucide-react';

const ViewOrder = () => {
    const location = useLocation();
    const order = location.state?.order;

    if (!order) {
        return <div className="text-center p-4">לא נמצאה הזמנה</div>;
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-50" dir="rtl">
            {/* Header */}
            <div className="bg-white shadow-lg p-6 sticky top-0 z-10">
                <h2 className="text-3xl font-bold text-center text-emerald-600">
                    הזמנה #{order.order_number}
                </h2>
            </div>

            <div className="flex-grow p-4 md:p-6 max-w-4xl mx-auto w-full">
                {/* Order Status Card */}
                <div className="bg-emerald-50 rounded-xl p-6 mb-6 border border-emerald-100">
                    <div className="flex items-center justify-between mb-4">
                        <span className="text-emerald-700 font-semibold">סטטוס הזמנה</span>
                        <span className="bg-emerald-500 text-white px-4 py-1 rounded-full text-sm">
                            התקבלה
                        </span>
                    </div>
                    <div className="flex items-center text-gray-600">
                        <Clock size={20} className="ml-2" />
                        <span>{new Date(order.creation_time).toLocaleString('he-IL')}</span>
                    </div>
                </div>

                {/* Customer Details Card */}
                <div className="bg-white rounded-xl shadow-md p-6 mb-6">
                    <h3 className="text-xl font-bold mb-4 text-gray-800">פרטי לקוח</h3>
                    <div className="space-y-4">
                        <div className="flex items-center">
                            <User size={20} className="text-gray-400 ml-3" />
                            <div>
                                <p className="text-sm text-gray-500">שם</p>
                                <p className="font-medium">{order.owner_name}</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Phone size={20} className="text-gray-400 ml-3" />
                            <div>
                                <p className="text-sm text-gray-500">טלפון</p>
                                <p className="font-medium">{order.phone_num}</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Users size={20} className="text-gray-400 ml-3" />
                            <div>
                                <p className="text-sm text-gray-500">פרטי הזמנה</p>
                                <p className="font-medium">
                                    {order.orderType === 'single' ? 'הזמנה בודדת' : 'הזמנה קבוצתית'} • {order.groupSize} סועדים
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Meals Card */}
                <div className="bg-white rounded-xl shadow-md p-6">
                    <div className="flex items-center mb-4">
                        <Utensils size={24} className="text-emerald-600 ml-2" />
                        <h3 className="text-xl font-bold text-gray-800">פירוט מנות</h3>
                    </div>
                    <div className="space-y-4">
                        {order.meals.map((meal, index) => (
                            <div
                                key={index}
                                className="p-4 rounded-lg bg-gradient-to-l from-gray-50 to-white border border-gray-100 hover:shadow-md transition-shadow"
                            >
                                <div className="flex justify-between items-start">
                                    <div>
                                        <p className="font-medium text-gray-900">{meal.meal}</p>
                                        <p className="text-sm text-gray-500 mt-1">{meal.name}</p>
                                    </div>
                                    <span className="bg-gray-100 text-gray-600 px-3 py-1 rounded-full text-sm">
                                        מנה {index + 1}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewOrder;